import React from 'react';

const Required = ({ type }) => {
  if (type === 0) {
    return (
      <small style={{
        backgroundColor: '#e00010', color: 'white', padding: '0 10px', borderRadius: '5px', fontSize: '0.7em', fontWeight: 300, marginLeft: '10px',
      }}
      >
        必須
      </small>
    );
  }
  if (type === 1) {
    return (
      <small style={{
        backgroundColor: '#888888', color: 'white', padding: '0 10px', borderRadius: '5px', fontSize: '0.7em', fontWeight: 300, marginLeft: '10px',
      }}
      >
        任意
      </small>
    );
  }
  return (
    <></>
  );
};

export default Required;
