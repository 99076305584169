import React, { ReactNode } from 'react';
import { Container } from 'reactstrap';

interface LayoutProps {
  children: ReactNode;
}

export const ContentTilte = ({ children }: LayoutProps) => (
  <div
    style={{
      width: '100%',
      height: '60px',
      backgroundColor: '#262626',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

export const ContentBody = ({ children }: LayoutProps) => (
  <div className="content-body" style={{ width: '100%' }}>
    <Container fluid>{children}</Container>
  </div>
);
