/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import './Pagination.css';

function PaginationComponent(props: any) {
  const [currentPage, setCurrentPage] = useState(props.page);
  const [limit] = useState(props.limit);
  const { totalPage } = props;
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
    props.parentCallback(limit * (Number(event.target.id) - 1));
  };
  const pages:number[] = [];
  for (let i = 1; i <= totalPage; i += 1) {
    pages.push(i);
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={number}
          id={number.toString()}
          onClick={handleClick}
          className={currentPage === number ? 'active' : ''}
        >
          {number}
        </li>
      );
    }
    return null;
  });

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);
    props.parentCallback(limit * (currentPage));
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    props.parentCallback(limit * (currentPage - 2));
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn;
  if (pages.length > maxPageNumberLimit) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    pageIncrementBtn = <li onClick={handleNextBtn}> &hellip; </li>;
  }

  let pageDecrementBtn;
  if (minPageNumberLimit >= 1) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    pageDecrementBtn = <li onClick={handlePrevBtn}> &hellip; </li>;
  }
  return (
    <>
      <div className="pagination">
        <ul className="pageNumbers" style={{ display: totalPage > 0 ? '' : 'none' }}>
          <li>
            <button
              onClick={handlePrevBtn}
              disabled={currentPage === pages[0]}
            >
              前へ
            </button>
          </li>
          {pageDecrementBtn}
          {renderPageNumbers}
          {pageIncrementBtn}

          <li>
            <button
              onClick={handleNextBtn}
              disabled={currentPage === pages[pages.length - 1]}
            >
              次へ
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default PaginationComponent;
