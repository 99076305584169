import * as CONST from '../ActionTypes';
import * as util from './index';

export const loading = () => ({
  type: CONST.LOADING,
});

export const unLoading = () => ({
  type: CONST.UNLOADING,
});

export const actionType = (typeName: any, payload: any) => ({
  type: typeName,
  payload,
});

export const requestSuccess = (typeName: any, result: any) => ({
  type: typeName,
  payload: result,
});

export const requestFailure = (typeName: any, result: any) => ({
  type: typeName,
  payload: result,
});

export const clearStatus = () => ({
  type: CONST.SEND_CONTACT_CLEAR,
});

export function setTokenCustomer(refreshToken: string) {
  localStorage.setItem('refresh_token_customer', refreshToken);
}

export function setTokenDriver(refreshToken: string) {
  localStorage.setItem('refresh_token_driver', refreshToken);
}

/**
 * 問い合わせる
 */
export const sendContact = (param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/public/contact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.SEND_CONTACT_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.SEND_CONTACT_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.SEND_CONTACT_FAILURE, error));
      dispatch(util.unLoading());
    });
};
/**
 * マッチングリクエストを取得
 */
export const getMatchingRequest = () => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/public/matching_request`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_MATCHING_REQUEST_SUCCESS, payload));
      dispatch(util.unLoading());
      return payload;
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_MATCHING_REQUEST_FAILURE, error));
      dispatch(util.unLoading());
      return [];
    });
};
