/* eslint-disable max-len */
import * as CONST from '../../ActionTypes';
import * as util from '../index';

/**
 * 見積依頼履歴詳細取得
 */
export const getDetailDeliveryRequest = (accessToken, id, isTemporarily = false) => (dispatch) => {
  dispatch(util.loading());
  const end_point = isTemporarily ? 'delivery_request_temporarily' : 'delivery_requests';
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/${end_point}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      // eslint-disable-next-line max-len
      dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_DETAIL_DELIVERY_REQUEST_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 見積依頼一覧取得
 */
export const getListDeliveryRequest = (accessToken, param, isTemporarily = false) => (dispatch) => {
  dispatch(util.loading());
  const end_point = isTemporarily ? 'delivery_request_temporarily' : 'delivery_requests';
  const status = param.status ? `&status=${param.status}` : '';
  const sort = param.sort ? `&sort=${param.sort}.desc` : '';
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/${end_point}?limit=${param.limit}&offset=${param.offset}${sort}${status}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_DELIVERY_REQUEST_CUSTOMER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_DELIVERY_REQUEST_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 見積依頼投稿
 */
export const postDeliveryRequest: any = (accessToken, param, isTemporarily = false, postTemporarily = false) => (dispatch) => {
  dispatch(util.loading());
  const end_point = isTemporarily ? 'delivery_request_temporarily' : 'delivery_requests';
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/${end_point}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then(async (payload) => {
      if (payload.status === 201) {
        dispatch(util.requestSuccess(CONST.CREATE_DELIVERY_REQUEST_CUSTOMER_SUCCESS, null));
      }
      if (payload.status === 500 || payload.status === 400) {
        const { detail } = await payload.json();
        if (!isTemporarily && postTemporarily) {
          dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE, detail || null));
        } else {
          dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_CUSTOMER_FAILURE, detail || null));
        }
        dispatch(util.unLoading());
        return false;
      }
      dispatch(util.unLoading());
      return true;
    })
    .catch((error) => {
      if (!isTemporarily && postTemporarily) {
        dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE, error));
      } else {
        dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_CUSTOMER_FAILURE, error));
      }
      dispatch(util.unLoading());
      return false;
    });
};

/**
 * 見積依頼編集
 */
export const updateDeliveryRequest : any = (accessToken, id, param, isTemporarily = false) => (dispatch) => {
  dispatch(util.loading());
  const end_point = isTemporarily ? 'delivery_request_temporarily' : 'delivery_requests';
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/${end_point}/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 201) {
        dispatch(util.requestSuccess(CONST.CREATE_DELIVERY_REQUEST_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
        return true;
      }
      dispatch(util.unLoading());
      return response.json();
    })
    .then((payload) => {
      if (!payload.detail) {
        dispatch(util.unLoading());
        return true;
      }
      dispatch(util.unLoading());
      if (isTemporarily) {
        dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE, payload.detail));
      } else {
        dispatch(util.actionType(CONST.UPDATE_DELIVERY_REQUEST_CUSTOMER_FAILURE, payload.detail));
      }
      return false;
    })
    .catch((error) => {
      if (isTemporarily) {
        dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER_FAILURE, error));
      } else {
        dispatch(util.actionType(CONST.UPDATE_DELIVERY_REQUEST_CUSTOMER_FAILURE, error));
      }
      dispatch(util.unLoading());
      return false;
    });
};

/**
 * 配信リクエストのステータスを編集する
 */
export const UpdateStatusDeliveryRequest = (accessToken, id, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/delivery_requests/statuses/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 201 || response.status === 204) {
        dispatch(util.requestSuccess(CONST.UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        // eslint-disable-next-line max-len
        dispatch(util.actionType(CONST.UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_FAILURE, payload.detail));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.UPDATE_STATUS_DELIVERY_REQUEST_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 配信リクエストのステータスを編集する
 */
export const PostQuestionnairesStatusDeliveryRequest = (accessToken, id, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/delivery_requests/questionnaires/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 201 || response.status === 204) {
        // eslint-disable-next-line max-len
        dispatch(util.requestSuccess(CONST.CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS, null));
        dispatch(util.actionType(CONST.DRIVER_CHECK_BADGES_FAILURE, ''));
        dispatch(util.unLoading());
        return null;
      }
      dispatch(util.unLoading());
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        // eslint-disable-next-line max-len
        dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE, payload.detail));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      // eslint-disable-next-line max-len
      dispatch(util.actionType(CONST.CREATE_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 配達要求の質問票を取得する顧客
 */
export const GetQuestionnairesStatusDeliveryRequest = (accessToken, id, suggID) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/delivery_requests/questionnaires/${id}/${suggID}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      // eslint-disable-next-line max-len
      dispatch(util.actionType(CONST.GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_DELIVERY_REQUEST_QUESTIONNAIRES_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
/**
 * CLEAR
 */
export const clearPostDeliveryRequest = () => (dispatch) => {
  dispatch(util.actionType(CONST.CLEAR_RESULT_CREATE_DELIVERY_REQUEST_CUSTOMER, null));
};
export const clearPostDeliveryRequestTemp = () => (dispatch) => {
  dispatch(util.actionType(CONST.CLEAR_RESULT_CREATE_DELIVERY_REQUEST_TEMP_CUSTOMER, null));
};

export const getSuggestionListRequest = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  const textTmp = (param.isApproved === 'newSuggestion' ? '&new_suggestion=true' : `&is_approved=${param.isApproved != null ? param.isApproved : ''}`);
  const textTmp1 = (param.isApproved === 'progress' ? '&is_progress=true' : textTmp);
  // eslint-disable-next-line no-nested-ternary
  const text = param.isApproved === 'reject' ? '&is_reject=true' : (param.isApproved === 'canceled' ? '&is_canceled=true' : textTmp1);
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/messages?limit=${param.limit}&offset=${param.offset}${text}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_SUGGUESTIONS_CUSTOMER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_SUGGUESTIONS_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
/**
 * DELETE
 */
export const deleteDeliveryRequestTempo : any = (accessToken, id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/delivery_request_temporarily/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: null,
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_CUSTOMER_SUCCESS, null));
        dispatch(util.unLoading());
      }
      if (response.status === 404) {
        dispatch(util.requestSuccess(CONST.VERIFY_TOKEN_CUSTOMER_FAILURE, 'トークンの有効期限が切れています'));
        dispatch(util.unLoading());
        return false;
      }
      return true;
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.VERIFY_TOKEN_CUSTOMER_FAILURE, error.detail));
      dispatch(util.unLoading());
      return false;
    });
};

/**
 * 見積依頼履歴詳細取得
 */
export const getDetailSuggestionDeliveryRequest = (accessToken, id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/customers/messages/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      // eslint-disable-next-line max-len
      dispatch(util.actionType(CONST.GET_SUGGUESTIONS_CUSTOMER_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_SUGGUESTIONS_CUSTOMER_FAILURE, error));
      dispatch(util.unLoading());
    });
};
